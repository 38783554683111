/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-unresolved */
import auditchain1 from 'assets/img/projects/auditchain/1.jpg';
import auditchain1phone from 'assets/img/projects/auditchain/1phone.jpg';
import autumnleaf from 'assets/img/projects/autumnleaf/1.jpg';
import autumnleafphone from 'assets/img/projects/autumnleaf/1phone.jpg';
import soundbooth1 from 'assets/img/projects/soundbooth/1.jpg';
import soundbooth1phone from 'assets/img/projects/soundbooth/1phone.jpg';

export const projects = [
  {
    id: 1,
    owner: 'Lenny Mirabile',
    title: 'autumnleaflandscape.com',
    url: 'https://autumnleaflandscape.com',
    description: 'Full stack developer, developed UI for configuring patios, browsing completed projects and services, developed endpoints for subscribing to newsletter and contacting company.',
    stack: ['NextJS', 'MongoDB', 'MailChimp', 'MUI'],
    videos: [],
    pics: [{ id: 1, url: autumnleaf, type: 'photo' },
    ],
    phonePics: [{ id: 1, url: autumnleafphone, type: 'photo' },
    ],
    teamMembers: 3,
    joined: '2014',
    developer: 'CodeFox',
    role: 'Full Stack Developer',
  },
  {
    id: 2,
    owner: 'Joe Kotz',
    title: 'soundbooth.com',
    url: 'https://soundbooth.com',
    path: 'soundbooth',
    description: 'Lead full stack developer and server admin, developed product and service marketplace with store fronts with third-party purchases, projectss with third-party purchases, in-app purchases, remote file storage, instant messaging, chat, analytics and negotiating.',
    stack: ['React', 'Express', 'Stripe', 'Socket.io', 'MUI', 'S3', 'Ubuntu', 'AdSense'], // drupal/mysql/sql, mongo?
    videos: [],
    pics: [
      { id: 1, url: soundbooth1, type: 'photo' },
    ],
    phonePics: [
      { id: 1, url: soundbooth1phone, type: 'photo' },
    ],
    teamMembers: 7,
    joined: '2018',
    developer: 'CodeFox',
    role: 'Lead Full Stack Developer & Server Admin',
  },
  {
    id: 3,
    owner: 'Jason Meyers',
    title: 'auditchain.finance',
    url: 'https://auditchain.finance',
    path: 'auditchain',
    description: 'Lead Front end developer and backend developer, developed UI for making ethereum and blockchain purchases, reviewing blockchain events, storing and retrieving files on IPFS, rendering XBRL financial reports from IPFS reports, rendering node operators on 3D globe.',
    stack: ['React', 'Express', 'Metamask', 'Ethereum', 'Polygon', 'MUI', 'D3'],
    videos: [],
    pics: [
      { id: 1, url: auditchain1, type: 'photo' },
    ],
    phonePics: [
      { id: 1, url: auditchain1phone, type: 'photo' },
    ],
    joined: '2017',
    teamMembers: 14,
    developer: 'CodeFox',
    role: 'Full Strack Developer',
  }
];
